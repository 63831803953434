import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'threehorizons';

  public open(item) {

    switch(item) {
      case 'who': {
        console.log('who');
        document.getElementById('container').className = 'box box-delta';
        break;
      }
      case 'what': {
        console.log('what');
        document.getElementById('container').className = 'box box-gamma';
        break;
      }
      case 'why': {
        console.log('why');
        document.getElementById('container').className = 'box box-beta';
        break;
      }
    }

  }
}
