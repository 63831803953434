<div id="container" class="box box-delta">
  <div class="alpha">
    <div class="inner-container">
      <img class="logo" src="assets/logo.png" />
      <ul class="nav">
        <li class="who" (click)="open('who')">Wie</li>
        <li class="what" (click)="open('what')">Wat</li>
        <li class="why" (click)="open('why')">Waarom</li>
      </ul>
    </div>
  </div>
  <div class="beta">
    <!-- <img class="curve" src="assets/pink.svg"> -->
    <svg
      class="curve"
      width="1000"
      height="38px"
      viewBox="0 0 1000 38"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
    >
      <g
        id="Pink"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M2.27373675e-13,35 C446,-10 685,-2 1000,10 C1000,20.6666667 1000,30.6666667 1000,40 L2.27373675e-13,39 C2.27373675e-13,37.2187534 2.27373675e-13,36.2187534 2.27373675e-13,36 C2.27373675e-13,35.6159647 2.27373675e-13,37.5165009 2.27373675e-13,35 Z"
          id="Line"
          fill="#FF99FF"
        ></path>
      </g>
    </svg>
    <div class="inner-container">
      <h2>Waarom</h2>
      <p class="quote">
        "We're at the beginning of the 4th Industrial Revolution. The Velocity
        of disruption and the acceleration of innovation are hard to comprehend
        or anticipate." <br /><b
          >K. Schwab, Founder& Executive Chairman, World EconomicForum</b
        >
      </p>

      <p>
        Ontwikkelingen volgen elkaar in een razendsnel tempo op. En de
        maatschappelijke opgaven, worden daarmee steeds complexer. Dat vraagt om
        een enorm adaptief vermogen van mensen en organisaties.
      </p>
      <p>
        Flexibiliteit, alertheid en veranderkundig vermogen zijn essentiële
        kwaliteiten om de toekomst tegemoet te treden. Dáár zit mijn missie als
        veranderkundige. In elke opdracht die ik aanneem, voeg ik flexibiliteit,
        alertheid en veranderkundig vermogen toe. Voor iedere bestuurder,
        manager en professional die opstaat om het verschil te maken. In de
        vraagstukken van nu, morgen en overmorgen.
      </p>
      <p>
        Ik ga met enthousiasme, creativiteit en oprechte aandacht naast je staan
        in de verandering. Soms loop ik net wat uit de pas om het perspectief op
        je opgave op te rekken en het beeld op mogelijke oplossingsrichtingen te
        verbreden.
      </p>
    </div>
  </div>
  <div class="gamma">
    <svg
      class="curve"
      width="1000px"
      height="71px"
      viewBox="0 0 1000 71"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
    >
      <g
        id="Gray"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M0,72 C0,56.6666667 0,35.3333333 0,8 C132.5,2.5 538,-16 1000,66 C1000,68 1000,69.6666667 1000,71 L0,72 Z"
          id="Line-2"
          fill="#8BA3AF"
        ></path>
      </g>
    </svg>
    <div class="inner-container">
      <h2>Strategisch advies</h2>
      <p class="quote">
        "Verandering zal niet komen als wij wachten op een andere persoon of een
        andere keer. Wij zijn degenen waarop we hebben gewacht. Wij zijn de
        verandering die we zoeken." <br /><b>Barack Obama</b>
      </p>

      <p>
        Als strategisch adviseur sta ik naast jouw organisatie en in jullie
        opgave. Ik breng veranderkundige kennis, netwerk en doorleefde ervaring
        in. Daarmee daag ik jullie uit om het perspectief op de opgave te
        verbreden. Dit brengt meerdere oplossingen en veranderroutes naar boven
        om het doel te realiseren. Vanuit dat kader help ik om de route concreet
        te maken en uit te zetten.
      </p>

      <p class="extra-bottom-marge">
        Ik verdwijn langzaam uit zicht, zodra het doel is behaald en de
        organisatie stevig in de verandering staat. Deze leerervaring maakt je
        robuuster voor toekomstige veranderingen.
      </p>

      <h2>Programmamanagement</h2>
      <p class="quote">
        "No one knows everything, everyone knows something. All knowledge
        resides in networks."<br />
        <b>Pierre Levy</b>
      </p>

      <p>
        Complexe en langlopende veranderingen vragen om flexibiliteit, alertheid
        en coördinatie. Een programmatische aanpak is hierop een passend
        antwoord. Als programmamanager pak ik de regie om jullie verandering te
        realiseren.
      </p>

      <p>
        Samen met de 'eigenaren' van de verandering maak ik het programmaplan.
        Dit is het kompas voor de verandering en wordt gebouwd rond vijf
        essentiële vragen:
      </p>
      <ul>
        <li>Waarom is deze verandering noodzakelijk?</li>
        <li>Waartoe gaat onze inzet leiden?</li>
        <li>Wat moeten wij concreet ondernemen om die ambitie te bereiken?</li>
        <li>Welke veranderstrategie zetten we daarvoor in?</li>
        <li>Wie zijn van belang in deze verandering?</li>
      </ul>

      <p class="extra-bottom-marge">
        Met deze aanpak worden de inhoudelijke koers en de belangen van de
        betrokkenen zichtbaar en voelbaar. Ik bouw aan de spelregels voor de
        samenwerking en besluitvorming. Vanuit dat kader gaat het programma naar
        de uitvoeringsfase. In deze fase wordt het aantal betrokkenen groter en
        worden initiatieven gebouwd en gestart die de verandering realiseren. Ik
        voeg hier een cyclus van continu leren aan toe. Hiermee ontwikkelen de
        experimenten zich tot routines en de startende samenwerking tot een
        volwassen partnerschap.
      </p>

      <h2>Coaching op persoonlijk leiderschap in verandering</h2>
      <p class="quote">
        "May your choices reflect your hopes, not your fears." <br /><b
          >Nelson Mandela</b
        >
      </p>

      <p>
        Bij coaching vraagstukken staat het persoonlijk leiderschap van jou als
        veranderaar centraal. Jouw rol in de complexe verandering. Dat
        leiderschap gaat vaak over twee kernpunten:
      </p>
      <ol>
        <li>
          Het inzetten van jezelf (de veranderaar) als instrument van de
          verandering.
        </li>
        <li>
          Een meervoudig perspectief op de verandering en veranderkundige
          vaardigheid om een verandering te regisseren.
        </li>
      </ol>
      <p>
        Deze punten kunnen we niet los van elkaar zien en pakken we altijd
        gezamenlijk op.
      </p>
      <p>
        Het inzetten van jezelf als instrument gaat voornamelijk over het
        'ontluiken' van inzicht in wie jij bent, hoe jij naar je vraagstuk kijkt
        en hoe jij jezelf als gevolg daarvan in dat vraagstuk inzet. We proberen
        dat via reflectie los te maken. Deze reflectie gaat via hoofd, hart en
        handen.
      </p>
      <p>
        We brengen samen orde aan in jouw verhaal en we ontleden concrete
        situaties. Dit geeft diepere inzichten in hoe je jouw vraagstuk benadert
        en waarom je dat zo doet. Vanuit het besef dat dit één mogelijke, maar
        niet de enig denkbare benadering is. Door mogelijke alternatieven te
        bekijken, krijgen we inzicht in je voorkeurspatronen en in de
        mogelijkheden om het anders te doen.
      </p>
      <p>
        Daarnaast breng ik vaardigheden, inzichten en veranderkundige expertise
        in die relevant zijn voor jouw vraagstuk. Ik help je te experimenteren
        met nieuw gedrag, zodat jij jouw (ongewenste) patronen en die van
        anderen kunt doorbreken. Met als doel dat jij de regie neemt over jouw
        verandering.
      </p>
    </div>
  </div>
  <div class="delta">
    <svg
      class="curve"
      width="1000px"
      height="30px"
      viewBox="0 0 1000 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      preserveAspectRatio="none"
    >
      <g
        id="gray-light"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M0,24 C377,-7 873,-2 1000,7 C1000,15.6666667 1000,23.3333333 1000,30 L0,30 C0,28 0,26 0,24 Z"
          id="Line-3"
          fill="#E7E4E7"
        ></path>
      </g>
    </svg>
    <div class="inner-container">
      <h2>Over Three Horizons</h2>
      <p>
        De voorlopers van vandaag maken de wereld van morgen. Anders. Mooier. Of
        juist slimmer. En duurzaam beter, dankzij het vermogen om anderen te
        inspireren. Dankzij het lef om op te durven staan. Om verder te kijken
        dan de horizon en de paden erachter uit te tekenen. Wegen waarop anderen
        kunnen volgen. Die ruimte en ontdekkingskracht geven aan weer nieuwe
        veranderaars.
      </p>
      <p class="extra-bottom-marge">
        Three Horizons ondersteunt de voorlopers van een duurzame maatschappij.
      </p>
      <h2>Over Mij</h2>

      <p class="quote">
        <img src="assets/jose.png" width="190" class="jose" />
        <span>"</span>Nieuwsgierigheid en verwondering zijn mijn startpunt, het
        begeleiden van verandering is mijn pad en duurzame vernieuwing en impact
        zijn de beloning.<span>"</span><br />
        <b>Joost Boerenkamp</b>
      </p>

      <p>
        Als ik twee eeuwen geleden geboren zou zijn, was ik ontdekkingsreiziger
        geworden. Omdat tijdreizen (nog) niet mogelijk is, richt ik mij op een
        andere ontdekkingstocht. Die van het ontrafelen van maatschappelijke
        vraagstukken.
      </p>

      <p>
        Van het ambacht veranderen heb ik mijn vak gemaakt. Als veranderkundige
        begeleid ik organisaties en professionals om hun duurzame omslag te
        realiseren. Met passie en precisie. Dit doe ik onder de vlag van Three
        Horizons en vanuit de overtuiging dat we nu moeten starten met de
        maatschappelijke uitdagingen van overmorgen. En dat we ruimte moeten
        geven aan initiatieven om anders en toekomstgericht te denken en te
        doen.
      </p>

      <p>
        Vanuit deze visie heb ik de afgelopen jaren regionale netwerken,
        programma's en projecten begeleid voor verandering in zorg, onderwijs en
        het sociaal domein. Ik voel me als een vis in het water in opstartende
        of vastzittende verandertrajecten. Trajecten waarbij er wel een beeld is
        van het vraagstuk, maar de oplossing en de weg ernaartoe nog onduidelijk
        zijn.
      </p>

      <br />
      <h2>Contact</h2>

      <p>
        De website van Three Horizons is bewust compact gehouden. Het is een
        introductie.<br />
        Als je interesse gewekt is ga ik graag het gesprek met je aan.<br />
        In dat gesprek kunnen we tot de kern van je vraag komen en merk jij of
        ik degene ben met wie je die vraag aan wilt gaan.
      </p>

      <p>
        <a href="mailto:joost@threehorizons.nl">joost@threehorizons.nl</a><br />

        <a href="tel:+31615126551">06-15126551</a>
      </p>
    </div>
  </div>
</div>
